import { AddCircle } from '@mui/icons-material'
import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    Modal,
    TextField,
} from '@mui/material'
import { AppContext } from 'app/contexts/AppContext'
import JobAccordion from 'app/views/jobs/StyledComponents/JobAccordion'
import {
    HelperText,
    StyledCard,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import { Fragment, useContext, useState } from 'react'
import * as Yup from 'yup'
import { valueCalc } from '../../method/method'
import NaturalMoistureContentTable from './NaturalMoistureContentTable'

const initialValues = {
    wt_wet_soil: '',
    dry_wt_soil: '',

    natural_moisture: '',
}

const validationSchema = Yup.object().shape({
    wt_wet_soil: Yup.number().required('Required'),
    dry_wt_soil: Yup.number().required('Required'),
    // natural_moisture: Yup.number().required('Required'),
})
const NaturalMoistureContent = ({
    result,
    isView,
    handleCustomRequirementChange,
    handleRequirementChange,
    overall,
    setOverall,
}) => {
    const handleClose = () => setOpen(false)
    const [open, setOpen] = useState(false)

    const handleOpen = () => setOpen(true)
    const [data, setData] = useState(result.data ? result.data : [])
    const [initialState, setInitialState] = useState({ ...initialValues })
    const { finalData, setFinalData } = useContext(AppContext)

    const handleSubmit = (values, { resetForm }) => {
        let arr = [...data]
        arr.push(values)
        let _finalData = finalData
        let avg = 0
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName && arr.length !== 0) {
                arr?.forEach((item1) => {
                    item1.natural_moisture = valueCalc(
                        100 *
                            ((item1.wt_wet_soil - item1.dry_wt_soil) /
                                item1.dry_wt_soil)
                    )
                    avg += Number(item1.natural_moisture / arr.length)
                })
            }

            if (item.groupName === result.groupName) {
                item.data = arr
                item.overall = {
                    ...overall,
                    average: valueCalc(avg),
                }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
        setData(arr)
        setInitialState({ ...initialValues })
        setOpen(false)
    }

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <JobAccordion
                        title={result?.groupName}
                        handleCustomRequirementChange={
                            handleCustomRequirementChange
                        }
                        handleRequirementChange={handleRequirementChange}
                        requirementvalue={overall?.requirements}
                        remarksValue={overall?.remarks}
                        overall={overall}
                    >
                        <Grid item xs={12}>
                            <Formik
                                initialValues={initialState}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {(props) => {
                                    const {
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                    } = props
                                    return (
                                        <Form>
                                            <Modal
                                                open={open}
                                                onClose={handleClose}
                                            >
                                                <StyledCard>
                                                    <CardHeader
                                                        title={
                                                            result?.groupName
                                                        }
                                                    />
                                                    <CardContent>
                                                        <Grid
                                                            container
                                                            spacing={4}
                                                        >
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Weight of wet soil, W1"
                                                                    required
                                                                    value={
                                                                        values.wt_wet_soil
                                                                    }
                                                                    name="wt_wet_soil"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.wt_wet_soil &&
                                                                        touched.wt_wet_soil
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.wt_wet_soil &&
                                                                    touched.wt_wet_soil
                                                                        ? errors.wt_wet_soil
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Weight of dry soil, W2"
                                                                    value={
                                                                        values.dry_wt_soil
                                                                    }
                                                                    name="dry_wt_soil"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                    error={
                                                                        errors.dry_wt_soil &&
                                                                        touched.dry_wt_soil
                                                                    }
                                                                />
                                                                <HelperText>
                                                                    {errors.dry_wt_soil &&
                                                                    touched.dry_wt_soil
                                                                        ? errors.dry_wt_soil
                                                                        : ''}
                                                                </HelperText>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                    <CardActions>
                                                        <Button
                                                            size="small"
                                                            onClick={
                                                                handleClose
                                                            }
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            size="small"
                                                            variant="contained"
                                                            onClick={
                                                                handleSubmit
                                                            }
                                                        >
                                                            Save
                                                        </Button>
                                                    </CardActions>
                                                </StyledCard>
                                            </Modal>
                                        </Form>
                                    )
                                }}
                            </Formik>
                            {data && (
                                <NaturalMoistureContentTable
                                    data={data}
                                    setData={setData}
                                    setFinalData={setFinalData}
                                    finalData={finalData}
                                    result={result}
                                    setOverall={setOverall}
                                    overall={overall}
                                />
                            )}
                            {isView !== true && (
                                <Grid container justifyContent="end">
                                    <Button
                                        sx={{ boxShadow: 'none', mt: 2 }}
                                        variant="contained"
                                        endIcon={<AddCircle />}
                                        onClick={handleOpen}
                                        size="small"
                                    >
                                        Add
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </JobAccordion>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default NaturalMoistureContent
