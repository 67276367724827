import React, { useState } from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    InputLabel,
    Modal,
    TextField,
    Typography,
} from '@mui/material'
import styled from '@emotion/styled'
import { AddCircle } from '@mui/icons-material'
import { Form, Formik } from 'formik'
import { AppContext } from 'app/contexts/AppContext'
import { useContext, useEffect } from 'react'
import * as Yup from 'yup'
import SoilFreeSwellIndexTable from './SoilFreeSwellIndexTable'
import {
    HelperText,
    StyledCard,
} from '../../../StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const initialValues = {
    water_height: '',
    kerosene_height: '',
    free_swell: '',
}

const validationSchema = Yup.object().shape({
    water_height: Yup.number().required('Required'),
    kerosene_height: Yup.number().required('Required'),
    // free_swell: Yup.number().required('Required'),
})
const SoilFreeSwellModal = (props) => {
    const { result, isView, overall, setOverall } = props
    const handleClose = () => setOpen(false)
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)

    const [data, setData] = useState(result.data ? result.data : [])
    const [initialState, setInitialState] = useState({ ...initialValues })
    const { finalData, setFinalData } = useContext(AppContext)

    const handleSubmit = (values, { resetForm }) => {
        let arr = [...data]
        arr.push(values)
        let _finalData = finalData
        let avg = 0
        _finalData.forEach((item) => {
            if (item?.groupName === result?.groupName && arr.length !== 0) {
                arr?.forEach((item1) => {
                    let total =
                        (100 * (item1.water_height - item1.kerosene_height)) /
                        item1.kerosene_height
                    avg += Number(total / arr.length)
                })
            }
            if (item.groupName === result.groupName) {
                item.data = arr
                item.overall = {
                    ...overall,
                    average: valueCalc(avg),
                }
                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
        setData(arr)
        setInitialState({ ...initialValues })
        setOpen(false)
        // resetForm()
    }

    return (
        <Grid item xs={12}>
            <Formik
                initialValues={initialState}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(props) => {
                    const {
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    } = props
                    return (
                        <Form>
                            <Modal open={open} onClose={handleClose}>
                                <StyledCard>
                                    <CardHeader title={result?.groupName} />
                                    <CardContent>
                                        <Grid container spacing={4}>
                                            <Grid item lg={8} sm={12}>
                                                <TextField
                                                    fullWidth
                                                    label="Water Height (h1)"
                                                    required
                                                    value={values.water_height}
                                                    name="water_height"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.water_height &&
                                                        touched.water_height
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.water_height &&
                                                    touched.water_height
                                                        ? errors.water_height
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid item lg={8} sx={12}>
                                                <TextField
                                                    fullWidth
                                                    label="Kerosene height (h2)"
                                                    value={
                                                        values.kerosene_height
                                                    }
                                                    name="kerosene_height"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.kerosene_height &&
                                                        touched.kerosene_height
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.kerosene_height &&
                                                    touched.kerosene_height
                                                        ? errors.kerosene_height
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            size="small"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            onClick={handleSubmit}
                                        >
                                            Save
                                        </Button>
                                    </CardActions>
                                </StyledCard>
                            </Modal>
                        </Form>
                    )
                }}
            </Formik>
            {data && (
                <SoilFreeSwellIndexTable
                    data={data}
                    setData={setData}
                    setFinalData={setFinalData}
                    finalData={finalData}
                    result={result}
                    overall={overall}
                    setOverall={setOverall}
                />
            )}
            {isView !== true && (
                <Grid container justifyContent="end">
                    <Button
                        sx={{ boxShadow: 'none', mt: 2 }}
                        variant="contained"
                        endIcon={<AddCircle />}
                        onClick={handleOpen}
                        size="small"
                    >
                        Add
                    </Button>
                </Grid>
            )}
        </Grid>
    )
}

export default SoilFreeSwellModal
