import {
    Alert,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material'
import { useState, useEffect } from 'react'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import IconButton from '@mui/material/IconButton'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { NonFormula } from '../../BDLList/BDLNonFormula'

const CommonField = ({
    type,
    testType_id,
    field,
    label,
    fieldData,
    requirement,
    setRequirement,
    selection,
    setSelection,
    dropDownField,
    setDropDownField,
    error,
    setError,
    formulaErrorMessage,
    setFormulaErrorMessage,
    result,
    data,
    applyFormula,
    editIndex,
    editedData,
    setEditedData,
    disabled,
    setIsEditing,
    isEditing,
    formulaData,
    materialName,
}) => {
    const [value, setValue] = useState([])
    const [select, setSelect] = useState('')
    // const [value, setValue] = useprevSelectionState(',')
    const filter = createFilterOptions()

    useEffect(() => {
        setValue(
            requirement[field + '_options'] !== undefined
                ? requirement[field + '_options']
                : [requirement[field]]
        )
    }, [fieldData, selection, field])

    // const handleChange = (e, field) => {
    //     const { value } = e.target
    //     setRequirement((prevRequirement) => ({
    //         ...prevRequirement,
    //         [field]: value,
    //     }))
    //     setSelection((prevSelection) => ({
    //         ...prevSelection,
    //         [field]: value,
    //     }))
    //     setDropDownField((prevDropDownField) => ({
    //         ...prevDropDownField,
    //         [field]: value,
    //     }))
    //     const selectedId = Array.isArray(fieldData)
    //         ? fieldData.find((data) => data[field] === value)?.id
    //         : null
    //     setSelect(selectedId)

    //     const updatedRequirement = applyFormula({
    //         ...requirement,
    //         [field]: value,
    //     })
    //     setRequirement(updatedRequirement)
    // }

    const handleChange = (e, field) => {
        const { value } = e.target
       
        if (
            testType_id === ( requirement.id ||editedData.id) ||
            (requirement.testParameter ) === 'E.coli' 
        ) {
            if (!/^-?\d*\.?\d*$/.test(value)) {
                setFormulaErrorMessage('Accept only numbers')
                return
            } 
            if (field === 'result' && /^-?\d*\.?\d*$/.test(value)) {
                setFormulaErrorMessage('Accept only numbers')
                return
            }
            else {
                setFormulaErrorMessage('')
            }
        }
        if (
            testType_id !== ( editedData.id) &&
            (editedData.testParameter ) !== 'E.coli' 
        ) {
            if (!/^-?\d*\.?\d*$/.test(value)) {
                setFormulaErrorMessage('Accept only numbers')
                return
            } 
            if (field === 'result' && /^-?\d*\.?\d*$/.test(value)) {
                setFormulaErrorMessage('Accept only numbers')
                return
            }
            else {
                setFormulaErrorMessage('')
            }
        }
        

        setEditedData((prevEditedData) => ({
            ...prevEditedData,
            [field]: value,
        }))
        if (field !== 'result' || field === 'result') {
            const updatedEditedData = applyFormula({
                ...editedData,
                [field]: value,
            })

            setEditedData(updatedEditedData)
            const updatedRequirement = applyFormula({
                ...requirement,
                [field]: value,
            })

            setRequirement(updatedRequirement)
        }
    }

    const handleTests = (e, fieldData, field) => {
        const currentData = fieldData?.find(
            (item) => item[field] === e.target.value
        )

        if (currentData) {
            let currentRow = { ...currentData }
            for (let key in currentRow) {
                if (
                    !key.endsWith('_options') &&
                    Array.isArray(currentRow[key])
                ) {
                    currentRow[key + '_options'] = [...currentRow[key]]
                    currentRow[key] = currentRow[key][0]
                }
            }

            setRequirement((prevRequirement) => ({
                ...prevRequirement,
                ...currentRow,
            }))
            setSelection((prevSelection) => ({
                ...prevSelection,
                ...currentRow,
            }))
            setDropDownField((prevDropDownField) => ({
                ...prevDropDownField,
                ...currentRow,
            }))
        }
    }

    // const handleCreatableInputChange = (newValue, field) => {
    //     console.log(newValue, 'sedrn')
    //     if (newValue && typeof newValue === 'object' && newValue.inputValue) {
    //         setRequirement((prevRequirement) => ({
    //             ...prevRequirement,
    //             [field]: newValue.inputValue,
    //         }))

    //         setSelection((prevSelection) => ({
    //             ...prevSelection,
    //             [field]: [newValue.inputValue],
    //         }))
    //     } else if (newValue) {
    //         setRequirement((prevRequirement) => ({
    //             ...prevRequirement,
    //             [field]: newValue,
    //         }))

    //         setSelection((prevSelection) => ({
    //             ...prevSelection,
    //             [field]: newValue,
    //         }))
    //     } else {
    //         setRequirement((prevRequirement) => ({
    //             ...prevRequirement,
    //             [field]: '',
    //         }))
    //     }
    // }
    const handleCreatableInputChange = (newValue, field) => {
        if (newValue && typeof newValue === 'object' && newValue.inputValue) {
            setEditedData((prevEditedData) => ({
                ...prevEditedData,
                [field]: newValue.inputValue,
            }))
            setRequirement((prevRequirement) => ({
                ...prevRequirement,
                [field]: newValue.inputValue,
            }))
            setSelection((prevSelection) => ({
                ...prevSelection,
                [field]: [newValue.inputValue],
            }))
        } else if (newValue) {
            setEditedData((prevEditedData) => ({
                ...prevEditedData,
                [field]: newValue,
            }))
            setRequirement((prevRequirement) => ({
                ...prevRequirement,
                [field]: newValue,
            }))
            setSelection((prevSelection) => ({
                ...prevSelection,
                [field]: [newValue],
            }))
        } else {
            setEditedData((prevEditedData) => ({
                ...prevEditedData,
                [field]: '',
            }))
            setRequirement((prevRequirement) => ({
                ...prevRequirement,
                [field]: '',
            }))
            setSelection((prevSelection) => ({
                ...prevSelection,
                [field]: '',
            }))
        }
    }

    const handleCreatableTests = (e, newValue, fieldData, field) => {
        if (newValue && typeof newValue === 'object' && newValue.inputValue) {
            setDropDownField((prevDropDownField) => ({
                ...prevDropDownField,
                [field]: newValue.inputValue,
            }))
        } else if (newValue) {
            setDropDownField((prevDropDownField) => ({
                ...prevDropDownField,
                [field]: newValue,
            }))
        }
    }
    return (
        <>
            {/* {type === 'TEXTBOX' &&
                (!testType_id ||
                    testType_id === requirement.id ||
                    testType_id === editedData.id) && (
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            label={label}
                            value={
                                requirement[field] ?? editedData[field] ?? ''
                            }
                            name={field}
                            onChange={(e) => {
                                handleChange(e, field)
                            }}
                            error={
                                error &&
                                field === 'result' &&
                                !requirement[field] &&
                                !editedData[field]
                            }
                            helperText={
                                error &&
                                field === 'result' &&
                                !requirement[field] &&
                                !editedData[field]
                                    ? 'Result Field is Required'
                                    : ''
                            }
                        />
                    </Grid>
                )} */}
            {type === 'TEXTBOX' &&
                (!testType_id ||
                    testType_id === requirement.id ||
                    testType_id === editedData.id) && (
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            label={
                                formulaData && label === 'Result'
                                    ? label + '(' + formulaData?.formula + ')'
                                    : label
                            }
                            value={
                                requirement[field] ?? editedData[field] ?? ''
                            }
                            name={field}
                            onChange={(e) => {
                                handleChange(e, field)
                            }}
                            error={
                                error &&
                                field !== 'unit' &&
                                field !== 'limit_field_1' &&
                                field !== 'limit_field_2' &&
                                field !== 'limit_field_main' &&
                                !requirement[field] &&
                                !editedData[field]
                            }
                            helperText={
                                error &&
                                field !== 'unit' &&
                                field !== 'limit_field_1' &&
                                field !== 'limit_field_2' &&
                                field !== 'limit_field_main' &&
                                !requirement[field] &&
                                !editedData[field]
                                    ? 'Please fill out this field'
                                    : ''
                            }
                        />
                    </Grid>
                )}

            {type === 'DROPDOWN' && (
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                            {label}
                        </InputLabel>
                        <Select
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={
                                requirement[field] ?? editedData[field] ?? ''
                            }
                            disabled={field === 'testParameter' && isEditing}
                            name={field}
                            label={label}
                            onChange={(e) => {
                                handleChange(e, field)
                                handleTests(e, fieldData, field)
                            }}
                        >
                            {fieldData?.map((value) => (
                                <MenuItem
                                    value={value[field] ?? ''}
                                    key={value[field]}
                                >
                                    {value[field] ?? ''}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            )}

            {type === 'DROPDOWN_ARRAY_STRING' && (
                <Grid item xs={12}>
                    <Autocomplete
                        value={requirement[field] ?? editedData[field] ?? ''}
                        onChange={(e, newValue) => {
                            setValue(newValue)
                            handleCreatableInputChange(newValue, field)
                            handleCreatableTests(newValue, fieldData, field)
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params)
                            const { inputValue } = params

                            const isExisting = options.some(
                                (option) => inputValue === option
                            )

                            if (inputValue !== '' && !isExisting) {
                                filtered.push(inputValue)
                            }
                            return filtered
                        }}
                        fullWidth
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="free-solo-with-text-demo"
                        // options={requirement[field + '_options'] ?? []}
                        options={[
                            // ...(requirement[field + '_options'] ?? []),
                            // ...(editedData[field + '_options'] ?? []),
                            ...(requirement[field + '_options'] ?? []).filter(
                                (option) => option !== '-'
                            ),
                            ...(editedData[field + '_options'] ?? []).filter(
                                (option) => option !== '-'
                            ),
                        ]}
                        renderOption={(props, option) => (
                            <li {...props}>{option}</li>
                        )}
                        getOptionLabel={(option) => option}
                        freeSolo
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={label}
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            <IconButton
                                                aria-label="search"
                                                onClick={() => {
                                                    console.log('Searching...')
                                                }}
                                            >
                                                <ArrowDropDownIcon />
                                            </IconButton>
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                    />
                </Grid>
            )}

            {type === 'dropDownField' && (
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                            {label}
                        </InputLabel>
                        <Select
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={
                                requirement[field] ?? editedData[field] ?? ''
                            }
                            name={field}
                            label={label}
                            onChange={(e) => {
                                handleChange(e, field)
                            }}
                        >
                            {fieldData?.map((value) => (
                                <MenuItem value={value ?? ''} key={value}>
                                    {value ?? ''}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            )}
        </>
    )
}

export default CommonField
