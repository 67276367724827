import styled from '@emotion/styled'
import { AddCircle } from '@mui/icons-material'
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    Modal,
    TextField,
} from '@mui/material'
import { AppContext } from 'app/contexts/AppContext'
import {
    HelperText,
    StyledCard,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import { useContext, useState } from 'react'
import * as Yup from 'yup'
import { valueCalc } from '../../method/method'
import SoilLiquidLimitTable from './SoilLiquidLimitTable'

const initialValues = {
    blow_count: '',
    wet_weight: '',

    dry_weight: '',
    moisture_content: '',
}
const validationSchema = Yup.object().shape({
    blow_count: Yup.number().required('Required'),
    wet_weight: Yup.number().required('Required'),

    dry_weight: Yup.number().required('Required'),
    // moisture_content: Yup.number().required('Required'),
})

const SoilLiquidLimitModal = ({ result, isView, overall, setOverall }) => {
    const handleClose = () => setOpen(false)
    const [open, setOpen] = useState(false)

    const handleOpen = () => setOpen(true)
    const [data, setData] = useState(result.data ? result.data : [])
    const [initialState, setInitialState] = useState({ ...initialValues })
    const { finalData, setFinalData } = useContext(AppContext)

    const handleSubmit = (values, { resetForm }) => {
        let arr = [...data]
        arr.push({
            ...values,
            moisture_content: valueCalc(
                (100 * (values.wet_weight - values.dry_weight)) /
                    values.dry_weight
            ),
        })
        let _finalData = finalData
        let avg = 0
        _finalData.forEach((item) => {
            // if (item.groupName === result.groupName && arr.length !== 0) {
            //     arr?.forEach((item1) => {
            //         let total = valueCalc(100 * (item1.wet_weight - item1.dry_weight) / item1.dry_weight)
            //         avg += Number(total / arr.length)
            //     })
            // }
            // if (item.groupName === result.groupName) {
            //     item.data = arr
            //     item.overall = {
            //         ...overall,
            //         average: valueCalc(avg)
            //     }
            //     setOverall(item.overall)
            // }
            // if (item.groupName === result.groupName) {
            //     item.data = arr;
            //     const filteredData = item.data.find(d => d.blow_count === "25" );
            //     const averageMoistureContent = filteredData?.moisture_content

            //     item.overall = {
            //         ...overall,
            //         average: averageMoistureContent
            //     };
            //     setOverall(item.overall);
            // }
            //
            if (item.groupName === result.groupName) {
                item.data = arr
                const filteredData25 = item.data.filter(
                    (d) => d.blow_count === '25'
                )

                const filteredData22to28 = item.data.filter((d) => {
                    const blowCount = parseInt(d.blow_count)
                    return blowCount >= 22 && blowCount <= 28
                })

                const averageMoistureContent25 =
                    filteredData25.length > 0
                        ? filteredData25.reduce(
                              (sum, d) => sum + parseFloat(d.moisture_content),
                              0
                          ) / filteredData25.length
                        : 0

                const averageMoistureContent22to28 =
                    filteredData22to28.length > 0
                        ? filteredData22to28.reduce(
                              (sum, d) => sum + parseFloat(d.moisture_content),
                              0
                          ) / filteredData22to28.length
                        : 0

                const averageMoistureContent =
                    filteredData25.length > 0
                        ? averageMoistureContent25
                        : averageMoistureContent22to28

                item.overall = {
                    ...item.overall,
                    average: averageMoistureContent,
                }

                setOverall(item.overall)
            }
        })
        setFinalData(_finalData)
        setData(arr)
        setInitialState({ ...initialValues })
        setOpen(false)
    }

    return (
        <Grid item xs={12}>
            <Formik
                initialValues={initialState}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(props) => {
                    const {
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    } = props
                    return (
                        <Form>
                            <Modal open={open} onClose={handleClose}>
                                <StyledCard>
                                    <CardHeader title="Liquid Limit" />
                                    <CardContent>
                                        <Grid container spacing={4}>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Blow count"
                                                    required
                                                    value={values.blow_count}
                                                    name="blow_count"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.blow_count &&
                                                        touched.blow_count
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.blow_count &&
                                                    touched.blow_count
                                                        ? errors.blow_count
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Wet weight, W1 (g)"
                                                    value={values.wet_weight}
                                                    name="wet_weight"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.wet_weight &&
                                                        touched.wet_weight
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.wet_weight &&
                                                    touched.wet_weight
                                                        ? errors.wet_weight
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Dry weight, W2 (g)"
                                                    value={values.dry_weight}
                                                    name="dry_weight"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.dry_weight &&
                                                        touched.dry_weight
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.dry_weight &&
                                                    touched.dry_weight
                                                        ? errors.dry_weight
                                                        : ''}
                                                </HelperText>
                                            </Grid>
                                            {/* <Grid
                                                item
                                                lg={6}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    label="Moisture content, 100*(W1-W2)/W2 %"
                                                    value={
                                                        values.moisture_content
                                                    }
                                                    name="moisture_content"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                        errors.moisture_content &&
                                                        touched.moisture_content
                                                    }
                                                />
                                                <HelperText>
                                                    {errors.moisture_content &&
                                                    touched.moisture_content
                                                        ? errors.moisture_content
                                                        : ''}
                                                </HelperText>
                                            </Grid> */}
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            size="small"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            onClick={handleSubmit}
                                        >
                                            Save
                                        </Button>
                                    </CardActions>
                                </StyledCard>
                            </Modal>
                        </Form>
                    )
                }}
            </Formik>
            {data && (
                <SoilLiquidLimitTable
                    data={data}
                    setData={setData}
                    setFinalData={setFinalData}
                    finalData={finalData}
                    result={result}
                    setOverall={setOverall}
                    overall={overall}
                />
            )}
            {isView !== true && (
                <Grid container justifyContent="end">
                    <Button
                        sx={{ boxShadow: 'none', mt: 2 }}
                        variant="contained"
                        endIcon={<AddCircle />}
                        onClick={handleOpen}
                        size="small"
                    >
                        Add
                    </Button>
                </Grid>
            )}
        </Grid>
    )
}

export default SoilLiquidLimitModal
