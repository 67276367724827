import { useContext, useState } from 'react'
import { AddCircle } from '@mui/icons-material'
import {
    Alert,
    Button,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    Modal,
} from '@mui/material'
import { JobHeaders, getStateValue } from 'app/assets/Constant/constant'
import { AppContext } from 'app/contexts/AppContext'
import { StyledCard } from 'app/views/jobs/StyledComponents/StyledComponents'
import CommonField from '../../CommonField/CommonField'
import WaterTable from './WaterTable'
import { NonFormula } from '../../../BDLList/BDLNonFormula'
import { Formula } from '../../../BDLList/BDLList'

const math = require('mathjs')

const WaterModal = ({
    result,
    isView,
    setOverall,
    overall,
    materialName,
    test_type,
    materialId,
}) => {
    // const handleClose = () => {
    //     setOpen(false)
    // }
    const handleClose = () => {
        setOpen(false)
        if (
            Object.entries(requirement).filter(([key, value]) => key !== 'id')
                .length === Object.keys(result?.header?.fields).length
        ) {
            setError(true)
        } else {
            setRequirement({})
            setSelection({})
            setDropDownField({})
            setEditIndex({})
            setEditedData({})
            setError(false)
        }
    }
    const valueObj = getStateValue()
    const [requirement, setRequirement] = useState(valueObj ?? {})
    const [selection, setSelection] = useState(valueObj ?? {})
    const test_method_options = getStateValue()
    const [dropDownField, setDropDownField] = useState(
        test_method_options ?? {}
    )
    const [editedData, setEditedData] = useState({})
    const [editIndex, setEditIndex] = useState(null)
    const [formula, setFormula] = useState('')
    const [findVariable, setFindVariable] = useState({})
    const [open, setOpen] = useState(false)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [formulaErrorMessage, setFormulaErrorMessage] = useState(false)
    // const handleOpen = () => setOpen(true)
    const handleOpen = () => {
        setOpen(true)
        setError(false)
        setFormulaErrorMessage(false)
    }
    const [data, setData] = useState(result.data ? result.data : [])
    const { finalData, setFinalData } = useContext(AppContext)
    const handleSubmit = () => {
        let hasError = false
        const testType_id = result?.header?.fields
        const valueArray = []
        testType_id.forEach(function (item) {
            if (item.testType_id !== '' && item.testType_id === requirement.id)
                valueArray.push(item.field)
        })
        for (let i = 0; i < valueArray.length; i++) {
            const s = valueArray[i]
            if (
                !requirement.hasOwnProperty(s) &&
                !editedData.hasOwnProperty(s)
            ) {
                hasError = true
                break
            }
        }
        // If there is an error, setError to true and return
        if (hasError || (!requirement.result && !editedData.result)) {
            setError(true)
        } else {
            // If there is no error, update data and other states
            let arr = [...data]
            arr.push(requirement)
            setError(false)
            result.data = arr
            setData(arr)
            setOpen(false)
            setRequirement({})
            setSelection({})
            setDropDownField({})
            setEditIndex({})
            setEditedData({})
        }
    }
    // const validationCheck = (requirement) => {
    //     if (!requirement.result) {
    //         // 'result' field is empty
    //         return true // Return true to indicate there is an error
    //     }
    //     return false // Return false if there are no errors
    // }

    const findIfFormula = (requirement) => {
        for (const key in requirement) {
            let type = result?.header?.fields?.find((i) => i?.field === key)
            if (type?.type === 'formula') {
                setFindVariable({ ...findVariable, field: type?.field })
                setFormula(type?.formula)
                return true
            }
        }
    }

    let resultField = result?.header?.fields?.find((i) => i?.resultField)
    let formulaData
    if (resultField && resultField.resultFormula) {
        const formula = resultField.resultFormula.find(
            (item1) =>
                item1.testType_id === requirement.id ||
                item1.testType_id === editedData.id
        )
        formulaData = formula
    }

    const applyFormula = (currentItem1) => {
        const currentItem = { ...currentItem1 }

        let resultField = result?.header?.fields?.find((i) => i?.resultField)

        if (resultField && resultField.resultFormula && currentItem?.id) {
            const formulaData = resultField.resultFormula.find(
                (item1) => item1.testType_id === currentItem.id
            )

            if (formulaData?.formula) {
                const scope = { ...currentItem }
                try {
                    const rawResult = math.evaluate(formulaData?.formula, scope)

                    const formula1 = test_type?.find(
                        (i) =>
                            i.name === currentItem.testParameter &&
                            i.material === materialId
                    )

                    const formula = formula1?.testmethod_option?.find(
                        (i) => i.default_test_method === currentItem.test_method
                    )

                    currentItem[resultField.field] =
                        currentItem.result &&
                        currentItem.testParameter === 'E.coli'
                            ? currentItem.result
                            : formula !== null &&
                                formula !== undefined &&
                                (rawResult.toFixed(2) ===
                                    formula.default_result_condition ||
                                    rawResult.toFixed(4) ===
                                        formula.default_result_condition)
                              ? formula.default_result
                              : rawResult.toFixed(2)

                    console.log(
                        currentItem[resultField.field],
                        currentItem,
                        'currentItem[resultField.field]'
                    )
                } catch (err) {
                    console.log(err, 'err')
                }
            }
        }
        return currentItem
    }

    const renderField = (item, idx) => {
        return (
            <CommonField
                type={item.type}
                field={item.field}
                label={item.label}
                fieldData={item.fieldData}
                setRequirement={setRequirement}
                requirement={requirement}
                selection={selection}
                setSelection={setSelection}
                dropDownField={dropDownField}
                setDropDownField={setDropDownField}
                error={error}
                setError={setError}
                formulaErrorMessage={formulaErrorMessage}
                setFormulaErrorMessage={setFormulaErrorMessage}
                result={result}
                testType_id={item.testType_id}
                editIndex={editIndex}
                editedData={editedData}
                setEditedData={setEditedData}
                applyFormula={applyFormula}
                formulaData={formulaData}
                materialName={materialName}
            />
        )
    }
    return (
        <Grid item xs={12}>
            <Modal open={open} onClose={handleClose}>
                <StyledCard>
                    <CardHeader title={materialName} />
                    <CardContent>
                        <Grid container spacing={4}>
                            {result?.header?.fields
                                ?.filter(
                                    (item) =>
                                        !item.resultField && !item.testType_id
                                )
                                .map((item, idx) => renderField(item, idx))}
                            {result?.header?.fields
                                ?.filter(
                                    (item) =>
                                        !item.resultField &&
                                        requirement.testParameter &&
                                        item.testType_id === requirement.id
                                )
                                .map((item, idx) => renderField(item, idx))}
                            {result?.header?.fields
                                ?.filter((item) => item.resultField)
                                .map((item, idx) => renderField(item, idx))}
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Button size="small" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button
                            size="small"
                            variant="contained"
                            onClick={handleSubmit}
                        >
                            Save
                        </Button>
                    </CardActions>
                </StyledCard>
            </Modal>
            {data && (
                <WaterTable
                    materialName={materialName}
                    data={data}
                    setData={setData}
                    setFinalData={setFinalData}
                    finalData={finalData}
                    result={result}
                    overall={overall}
                    setOverall={setOverall}
                    setRequirement={setRequirement}
                    requirement={requirement}
                    selection={selection}
                    setSelection={setSelection}
                    dropDownField={dropDownField}
                    setDropDownField={setDropDownField}
                    error={error}
                    setError={setError}
                    applyFormula={applyFormula}
                    handleSubmit={handleSubmit}
                    editedData={editedData}
                    setEditedData={setEditedData}
                    editIndex={editIndex}
                    setEditIndex={setEditIndex}
                    formulaErrorMessage={formulaErrorMessage}
                    setFormulaErrorMessage={setFormulaErrorMessage}
                    formulaData={formulaData}
                />
            )}
            {isView !== true && (
                <Grid container justifyContent="end">
                    <Button
                        sx={{ boxShadow: 'none', mt: 2 }}
                        variant="contained"
                        endIcon={<AddCircle />}
                        onClick={handleOpen}
                        size="small"
                    >
                        Add
                    </Button>
                </Grid>
            )}
        </Grid>
    )
}

export default WaterModal
